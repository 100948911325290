<template>
  <div class="editable-area" ref="top">
    <EditButton :callback="onEdit" :disabled="!displaying" :class="{'no-permission': !hasPermission, 'disabled': disabled}" />
    <slot name="display" v-if="displaying" />
    <slot name="edit" v-if="editing" class="{'updating': loading}" />
    <div class="loading-wrapper" v-if="loading">
      <CpLoading  />
    </div>
    <CancelButton :callback="onCancel" :disabled="loading" v-if="editing" />
    <SaveButton :callback="onSave" :disabled="loading" v-if="editing" />
  </div>
</template>

<script>
import { EditButton, CancelButton, SaveButton } from '../atoms/CpButton';
import CpLoading from '../atoms/CpLoading';
import scrollIt from '../../lib/animatingScroll';

export default {
  name: 'EditableArea',
  components: {
    EditButton,
    CpLoading,
    CancelButton,
    SaveButton
  },
  props: {
    values: {
      type: Object,
      required: true
    },
    form: {
      type: Object,
      required: true
    },
    callbackSave: {
      type: Function,
      required: true
    },
    callbackCancel: {
      type: Function,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      displaying: true,
      editing: false,
      loading: false
    };
  },
  computed: {
    hasPermission() {
      const role = ('application' in window && 'role' in window.application)
        ? window.application.role : '';
      return role !== 'EMPLOYER'
    },
    disabling() {
      return !this.hasPermission || this.disabled
    }
  },
  methods: {
    onEdit() {
      if (this.disabling) {
        return;
      }
      this.displaying = false;
      this.editing = true;
      this.$emit('editing', true);
    },

    onCancel() {
      this.displaying = true;
      this.editing = false;
      this.callbackCancel();
      this.$emit('editing', false);
      const self = this;
      this.$nextTick(() => {
        self.scrollToSectionTop();
      });
    },

    onSave() {
      this.loading = true;
      const self = this;
      return this.callbackSave().then(() => {
        self.loading = false;
        self.displaying = true;
        self.editing = false;
        self.$emit('editing', false);
        self.$nextTick(() => {
          self.scrollToSectionTop();
        });
      });
      // TODO: how to implement server error?
    },

    scrollToSectionTop() {
      scrollIt(this.$refs.top.parentElement.parentElement, 800, 'easeOutCubic', null);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../scss/atoms/_breakpoints.mixin';

.editable-area {
  min-height: 120px;
  position: relative;

  .loading-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: not-allowed;
    background-color: rgba(200, 200, 200, 0.4);
    z-index: 5;
  }

  /deep/ {
    .edit-button {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;

      &.no-permission {
        display: none;
      }
    }

    .cancel-button {
      position: absolute;
      bottom: 0;
      right: 135px;
    }

    .save-button {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
}

.updating {
  opacity: 0.4;
  cursor: not-allowed;
}

@include bp-max-tablet {
  .editable-area .edit-button {
    padding-right: 10px;
  }
}
</style>
