<template>
  <div class="greeting-card-text">
    <h3 class="greeting-card-title">{{ username }}</h3>

    <template v-if="jobOffer">
      <template v-if="jobOffer.appointment">
        <p>
          wir freuen uns auf das Telefonat mit Ihnen. Unser Karriere Experte meldet sich
          am {{getAppointmentStartDate(jobOffer.appointment)}} Uhr bei Ihnen.
        </p>
        <p>
          Richten Sie bereits jetzt Ihr Bewerberprofil ein. Dies hilft uns,
          Ihre Wünsche in Bezug auf Ihren neuen Job besser zu verstehen.
        </p>
        <p>
          <CpButtonAnchor to="dosier" color="blue" size="large full" weight="bold">
            Zum Bewerberprofil
          </CpButtonAnchor>
        </p>
      </template>

      <template v-if="!jobOffer.appointment">
        <p>
          sofern noch nicht geschehen, richten Sie Ihr Bewerberprofil ein.
          Dies hilft uns, Ihre Wünsche in Bezug auf Ihren neuen Job besser zu verstehen.
        </p>
        <p>
          Unsere Karriere Experten stehen für weiterführende Informationen gerne zur Verfügung.
          Buchen Sie sich hierzu einfach einen Telefontermin.
        </p>

        <p>
          <CpButtonAnchor
            v-if="availableAppointments.length"
            :to="{
              name: 'AppointmentForm',
              params: {processTrackerId: jobOffer.processTrackerId, sourcerId: jobOffer.sourcerId}
            }"
            color="blue" size="large full" weight="bold"
          >
            Termin buchen
          </CpButtonAnchor>

          <span v-if="!availableAppointments.length">
            Aktuell sind leider keine Termine bei unseren HR Consultants verfügbar.
          </span>
        </p>
      </template>
    </template>

    <template v-else>
      <p>auf dieser Seite präsentieren wir exklusive Jobangebote unserer Mandanten.</p>
      <p>
        Unsere HR Consultants stehen telefonisch für weiterführende Informationen gerne zur
        Verfügung. Sofern noch nicht geschehen, einfach einen Termin vereinbaren.
      </p>
    </template>
  </div>
</template>

<script>
import { formatDateHour } from '../../../../lib/utils';
import CpButtonAnchor from '../../../atoms/CpButton/CpButtonAnchor';

export default {
  name: 'AppointmentCard',

  components: {
    CpButtonAnchor
  },

  props: {
    username: {
      type: String,
      required: true
    },
    jobOffer: {
      type: Object,
      default: null
    },
    availableAppointments: {
      type: Array,
      default() {
        return [];
      }
    }
  },

  methods: {
    getAppointmentStartDate(appointment) {
      return formatDateHour(appointment)
    }
  }
};
</script>

<style lang="scss">
@import '../../../../scss/atoms/_colors';

.greeting-card-text {
  margin-top: 20px;
  border-top: 1px solid #c4c4c4;

  p {
    font-size: 17px;
  }
}

.greeting-card-title {
  font-weight: 900;
}

.greeting-card-messenger {
  font-size: 17px;

  textarea {
    display: block;
    width: 100%;
    height: 200px;
    border: 1px solid #c4c4c4;
    resize: none;
    font-size: 17px;
    padding: 14px 14px 0;

    &.error {
      background-color: #f8e0e0;
    }
  }
}

.contact-form {
  display: block;
  position: relative;

  &.loading {
    &:before {
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      width: 100%;
      height: 100%;
      background-color: $color-white;
      content: '';
    }

    &:after {
      position: absolute;
      left: 50%;
      top: 50%;
      display: block;
      width: 50px;
      height: 50px;
      background: transparent url('/assets/img/loader.svg') no-repeat center;
      background-size: contain;
      margin: -25px 0 0 -25px;
      content: '';
      animation: rotation 1.4s linear infinite;
    }
  }
}

.greeting-messenger-title {
  margin: 30px 0 14px;
  font-size: 17px;
  font-weight: 900;
}

.greeting-messenger-error {
  color: $color-red;
}

@keyframes rotation {
  0% {
    transform: rotate(0);
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
