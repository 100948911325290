<template>
  <footer class="cp-main-footer">
    <div class="container">
      <div class="cp-main-footer-upper clearfix">
        <CpHeader>
          <template slot="title">
            <Translation :hidden="true" resource_key="footer.header.title" @translated="setHeaderTitle" />
            <span v-html="headerTitle" />
          </template>
          <template slot="taglines">
            <Translation resource_key="footer.header.teasertext"/>
          </template>
        </CpHeader>
        <ContactAddress>
          <template slot="title">Career Peakr Deutschland GmbH</template>
          <template slot="contact">
            <p>
              Urbanstraße 116<br>10967 Berlin
            </p>
            <p>
              <span class="cp-social-link fontello icon-mail-alt">
                <a href="mailto:kontakt@careerpeakr.de">kontakt@careerpeakr.de</a>
              </span></p><p>
              <span class="cp-social-link fontello icon-phone">
                <a href="tel:+4930235909460">+49 (0)30 23 59 09 460</a>
              </span>
            </p>
          </template>
          <template slot="links">
            <SocialLink href="https://www.facebook.com/CareerPeakr" channel="facebook"/>
            <SocialLink href="https://www.instagram.com/careerpeakr/?hl=de" channel="instagram"/>
            <SocialLink href="https://www.xing.com/companies/careerpeakrdeutschlandgmbh" channel="xing"/>
            <SocialLink href="https://www.linkedin.com/company/careerpeakr" channel="linkedin"/>
            <SocialLink href="https://www.kununu.com/de/career-peakr-deutschland1" channel="kununu"/>
          </template>
        </ContactAddress>
      </div>
      <FooterNav/>
    </div>
  </footer>
</template>

<script>
import SocialLink from '../atoms/SocialLink'
import Translation from '../atoms/Translation';
import CpHeader from '../molecules/CpHeader';
import ContactAddress from '../molecules/ContactAddress';
import FooterNav from '../organisms/FooterNav';

export default {
  name: 'MainFooter',

  components: {
    SocialLink,
    Translation,
    CpHeader,
    ContactAddress,
    FooterNav
  },
  data() {
    return {
      headerTitle: ''
    };
  },
  methods: {
    setHeaderTitle({ value }) {
      console.log(value);
      this.headerTitle = value;
    }
  }
};
</script>

<style lang="scss">
@import '../../scss/atoms/colors';
@import '../../scss/atoms/breakpoints.mixin';

.cp-main-footer {
  background-color: $color-blacky;
  color: $color-white;
  padding: 125px 0 48px;

  .title {
    font-size: 24px;
    em {
      font-style: normal;
      color: $color-blue-light;
      white-space: nowrap;
    }
  }

  .taglines {
    font-size: 24px;
    margin-right: 35px; // for word breaking
  }

  p,
  a {
    color: $color-white;
  }
}

.cp-main-footer-upper {
  padding-bottom: 64px;
  display: flex;

  .header {
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .cp-address {
    float: left;
  }
}

@include bp-max-tablet() {
  .cp-main-footer {
    padding-top: 48px;

  }
}

@include bp-max-medium() {
  .cp-main-footer {
    .cp-main-footer-upper {
      flex-direction: column;
    }

    .header,
    .cp-address {
      width: 100%;

      .taglines {
        font-size: 28px;
        line-height: 37px;
      }

      .cp-address-name {
        font-size: 18px;
        line-height: 24px;
      }
    }
    .cp-address {
      margin-top: 48px;
    }
  }
}

</style>
